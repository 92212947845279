/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'signpost-split-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7 16h2V6h5a1 1 0 00.8-.4l.975-1.3a.5.5 0 000-.6L14.8 2.4A1 1 0 0014 2H9v-.586a1 1 0 00-2 0V7H2a1 1 0 00-.8.4L.225 8.7a.5.5 0 000 .6l.975 1.3a1 1 0 00.8.4h5z"/>',
    },
});
